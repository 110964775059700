/**
 * Set DataLayer Push for SMB resources - download documents
 * @version 1.0.0
 * @author Erik Ondrus <erik.ondrus@avast.com>
 * @tracks elements with: '[data-resource-action="download"]'
 * @requires elements with data attributes: data-resource-type, data-resource-headline, data-resource-url
 */

(function () {
	'use strict';

	var elementToTrack = '[data-resource-action="download"]';

	/*
	 * DataLayer push for download resources
	 * @param {Element} - element
	 * @returns {void}
	 */
	var dataLayerPushDownloadResource = function (el) {
		window.dataLayer = window.dataLayer || [];

		dataLayer.push({
			event: 'user.download.media',
			user: {
				download: {
					media: {
						type: el.getAttribute('data-resource-type') || '',						
						headline:
							el.getAttribute('data-resource-headline') || '',
						url: el.getAttribute('data-resource-url') || '',
					},
				},
			},
		});
	};

	document.querySelectorAll(elementToTrack).forEach(function (el) {
		el.addEventListener('click', function (event) {
			dataLayerPushDownloadResource(el);
		});
	});
})();
